import React from "react"
import css from "@styled-system/css"

import { Box, RichText, Flex, Text, Wrapper } from "components"

const Item = ({ label, children }) => (
  <Box as="li" width={["100%", 1 / 2, 1 / 4]}>
    <Text as="h6" fontSize={0}>
      {label}
    </Text>
    <Text as="div">{children}</Text>
  </Box>
)

const DirectInfo = ({ address, addressLink, hours, email, phone }) => (
  <Wrapper as="section" pt={5}>
    <Box pt={4} borderTop="1px solid" borderColor="border">
      <Flex
        as="ul"
        flexWrap="wrap"
        css={css({
          m: [-2, -4],
          p: 0,
          listStyle: "none",
          "> *": {
            p: [2, 4],
          },
        })}
      >
        {address && addressLink && (
          <Item label={`Office address`}>
            {address.text}
            <Text mt={1}>
              <a href={addressLink.url} target="_blank" rel="noopener">
                Get directions
              </a>
            </Text>
          </Item>
        )}
        {hours && (
          <Item label={`Hours`}>
            <RichText
              content={hours}
              css={css({
                "*": {
                  m: "0 !important",
                },
              })}
            />
          </Item>
        )}
        {email && (
          <Item label={`Email`}>
            <a children={email} href={`mailto:${email}`} />
          </Item>
        )}
        {phone && (
          <Item label={`Phone`}>
            <a children={phone} href={`tel:${phone}`} />
          </Item>
        )}
      </Flex>
    </Box>
  </Wrapper>
)

export default DirectInfo
