import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { PageHeader, SEO } from "components"
import { usePreviewData } from "utils"

import CallOut from "./_callOut"
import DirectInfo from "./_directInfo"
import Tiles from "./_tiles"

function ContactPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicContact {
          data {
            seo_title {
              text
            }
            seo_description {
              text
            }
            seo_image {
              url
              alt
            }
            hero_image {
              url
              alt

              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
            hero_video {
              url
            }
            hero_title {
              text
            }
            hero_subtitle {
              text
            }
            information_title {
              text
            }
            information_description {
              html
            }
            information_button_text
            information_button_link {
              link_type
              url
            }
            office_image {
              url
              alt

              fluid(maxWidth: 900) {
                ...GatsbyPrismicImageFluid
              }
            }
            address {
              text
            }
            address_link {
              url
            }
            address_coordinates {
              latitude
              longitude
            }
            email
            phone
            hours {
              html
            }
          }
        }
      }
    `
  )

  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicContact } = MERGED_DATA

  return (
    <>
      <SEO
        title={prismicContact.data.seo_title}
        description={prismicContact.data.seo_description}
        image={prismicContact.data.seo_image}
      />
      <PageHeader
        image={prismicContact.data.hero_image}
        video={prismicContact.data.hero_video}
        title={prismicContact.data.hero_title}
        subtitle={prismicContact.data.hero_subtitle}
      />
      <CallOut
        title={prismicContact.data.information_title}
        description={prismicContact.data.information_description}
        buttonText={prismicContact.data.information_button_text}
        buttonLink={prismicContact.data.information_button_link}
      />
      <DirectInfo
        address={prismicContact.data.address}
        addressLink={prismicContact.data.address_link}
        email={prismicContact.data.email}
        phone={prismicContact.data.phone}
        hours={prismicContact.data.hours}
      />
      <Tiles
        officeImage={prismicContact.data.office_image}
        addressLink={prismicContact.data.address_link}
        addressCoordinates={prismicContact.data.address_coordinates}
      />
    </>
  )
}

export default ContactPage
