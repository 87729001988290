import React from "react"
import css from "@styled-system/css"

import { Box, Button, H1, PrismicLink, Wrapper, RichText } from "components"

const CallOut = ({ title, description, buttonText, buttonLink }) => (
  <Wrapper as="section" pt={[5, 6]}>
    <Box>
      {title && <H1>{title.text}</H1>}
      {description && (
        <RichText
          content={description}
          mt={[3, 4]}
          fontSize={[2, 3, 4]}
          css={css({
            maxWidth: 600,
          })}
        />
      )}
      {buttonText && buttonLink && (
        <PrismicLink link={buttonLink}>
          <Button children={buttonText} mt={4} variant="default" />
        </PrismicLink>
      )}
    </Box>
  </Wrapper>
)

export default CallOut
