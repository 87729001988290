import React from "react"
import css from "@styled-system/css"

import { Box, Flex, Wrapper, Image } from "components"

const Tiles = ({ officeImage, addressLink, addressCoordinates }) => (
  <Wrapper as="section" pt={5}>
    <Flex
      flexWrap="wrap"
      css={css({
        m: [-2, -4],
        "> *": {
          p: [2, 4],
        },
      })}
    >
      {officeImage && (
        <Box width={["100%", 1 / 2]}>
          <Image src={officeImage} />
        </Box>
      )}
      {addressLink && addressCoordinates && (
        <Box width={["100%", 1 / 2]}>
          <div itemScope itemType="http://schema.org/LocalBusiness" />
          <Box
            as="a"
            href={addressLink.url}
            target="_blank"
            rel="noopener"
            display="block"
            height="100%"
            bg="text"
            style={{
              backgroundImage:
                "url(https://maps.googleapis.com/maps/api/staticmap?center=" +
                addressCoordinates.latitude +
                "%2c%20" +
                addressCoordinates.longitude +
                "&markers=anchor:bottomleft%7Cicon:http://partnershomes.netlify.com/images/officeflag.png%7C" +
                addressCoordinates.latitude +
                "%2c%20" +
                addressCoordinates.longitude +
                "&zoom=14&size=640x640&scale=2&key=" +
                process.env.GATSBY_GOOGLE_KEY +
                "&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e)",
            }}
            css={css({
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            })}
          />
        </Box>
      )}
    </Flex>
  </Wrapper>
)

export default Tiles
